import React, { useLayoutEffect, useEffect, useState, useRef } from 'react'

import offertesImage from '../images/functies/circular-component-screenshots/en/offertes.png'
import bonnenImage from '../images/functies/circular-component-screenshots/en/bonnen.png'
import facturenImage from '../images/functies/circular-component-screenshots/en/facturen.png'
import bankenImage from '../images/functies/circular-component-screenshots/en/banken.png'
import belastingImage from '../images/functies/circular-component-screenshots/en/belasting.png'
import rapporagesImages from '../images/functies/circular-component-screenshots/en/rapportages.png'
import headerImage from '../images/boekhoudinglp/headerImage.png'

import saveTimeImage from '../images/functies/split-view/en/bespaar-tijd.png'
import saveMoneyImage from '../images/functies/split-view/en/bespaar-geld.png'
import riskImage from '../images/functies/split-view/en/risico.png'

import { Cog, Document, Phone, Graph, Bank, Tax } from '../images/icons/heroicons'
import { Circle, Progress, Card } from '../components/pages/functies/circular'
import { reviewStructuredData } from '../components/layout/meta/schema-org'
import { Split, Quote, Promotion } from '../components/pages/functies'

import { RelativeContainer, ComponentContainer, ColoredBackground } from '../components/shared/landingPages/layoutComponents'
import { Page, Container, LastButNotLeast, Buttons } from './_functies.styles'

import { useElementSize, useInterval } from '../hooks'
import Header from '../components/shared/landingPages/header'
import { RegisterButton, Button } from '../components/UI'
import { Layout } from '../components/layout'
import Meta from '../components/layout/meta'

/**
 * @typedef {Object} Content
 * @property {JSX.Element} content.icon
 * @property {string} content.title
 * @property {string} content.text
 * @property {Blob} content.image
 * @property {string} content.url
 */

/**
 * @type {Content[]}
 */
const content = [
    { Icon: <Cog />, title: 'Quotes', text: 'Create professional quotes in a snap. In your own style.', image: offertesImage, url: 'offertes' },
    {
        Icon: <Document />,
        title: 'Scan receipts',
        text: 'Scan receipts and invoices with the Tellow app on your phone. Or easily upload your document.',
        image: bonnenImage,
        url: 'bonnen',
    },
    { Icon: <Phone />, title: 'Invoicing', text: 'Easily create and send invoices via your laptop or phone.', image: facturenImage, url: 'facturen' },
    { Icon: <Bank />, title: 'Bank integrations', text: 'Simply link your bank and automatically load your transactions.', image: bankenImage, url: 'koppel-je-rekening' },
    {
        Icon: <Tax />,
        title: 'Digital VAT declaration',
        text: 'Automated your VAT and income tax returns.',
        image: belastingImage,
        url: 'btw-aangifte',
    },
    {
        Icon: <Graph />,
        title: 'Reports',
        text: 'With the automatically generated reports, you always know exactly how your business is doing.',
        image: rapporagesImages,
        url: 'rapportages',
    },
]

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

/** Custom calculation hook. */
function useCalculatePoints(array, pageWidth, size) {
    const [points, setPoints] = useState([])

    const radius = pageWidth / 2
    const step = (2 * Math.PI) / array.length
    let angle = 0

    /** Calculate. */
    useIsomorphicLayoutEffect(() => {
        const calculation = array.map(() => {
            const x = Math.round(pageWidth / 2 + radius * Math.cos(angle) - size / 2)
            const y = Math.round(pageWidth / 2 + radius * Math.sin(angle) - size / 2)
            angle += step

            return { x: `${x}px`, y: `${y}px` }
        })

        setPoints(calculation)
    }, [pageWidth])

    return points
}

const Functies = () => {
    const [active, setActive] = useState(0)
    const [isPlaying, setIsPlaying] = useState(true)

    const elementRef = useRef(null)
    const { width } = useElementSize(elementRef)

    const elementSize = 250
    const leastAcceptableWidth = 1024
    const pageWidth = width > 800 ? 800 : width
    const points = useCalculatePoints(content, pageWidth, elementSize)

    // @see https://usehooks-ts.com/react-hook/use-interval
    useInterval(
        () => {
            const hasReachedLimit = active === content.length - 1
            const nextCard = active + 1

            setActive(hasReachedLimit ? 0 : nextCard)
        },
        // Delay in milliseconds or null to stop it
        isPlaying ? 3000 : null
    )

    return (
        <Layout>
            <Meta
                keywords={['zzp', 'boekhouden', 'tellow']}
                title="Read all about the functionalities of Tellow"
                description="Would you like to know more about Tellow? Here you will find explanations of all the features our software has to offer."
                path="/en/functies"
                jsonLD={reviewStructuredData}
            />

            <Header
                data={{
                    title: `Everything you need for good bookkeeping`,
                    textCTA: `Discover Tellow now`,
                    headerImage,
                }}
            />

            <ColoredBackground purple>
                <RelativeContainer ref={elementRef}>
                    <ComponentContainer margin={0} padding="2rem 0">
                        <Page style={{ '--size': `${pageWidth}px` }}>
                            <Container style={{ '--size': `${pageWidth}px` }}>
                                {points.map((dimensions, index) => (
                                    <Card
                                        key={`${index * 2}`}
                                        setActive={() => {
                                            setActive(index)
                                            setIsPlaying(false)
                                        }}
                                        onHoverLeave={() => {
                                            setIsPlaying(true)
                                        }}
                                        elementSize={elementSize}
                                        content={content[index]}
                                        dimensions={dimensions}
                                        activeIndex={active}
                                        pageWidth={width}
                                        index={index}
                                    />
                                ))}

                                {width > leastAcceptableWidth && <Circle source={content[active].image} />}
                                {width > leastAcceptableWidth && <Progress isPlaying={isPlaying} content={content} activeIndex={active} />}
                            </Container>
                        </Page>
                    </ComponentContainer>
                </RelativeContainer>
            </ColoredBackground>

            <ColoredBackground gradient purple>
                <RelativeContainer>
                    <ComponentContainer margin={0} padding="0 0 4rem 0">
                        <Promotion
                            copy="In addition to being the most user-friendly accounting apps, <wbr /> also one of the most complete apps."
                            CTA="Overview of all functions offered by Tellow"
                            url="/prijs#matrix"
                        />
                    </ComponentContainer>
                </RelativeContainer>
            </ColoredBackground>

            <ColoredBackground white>
                <RelativeContainer>
                    <ComponentContainer margin={0} padding="2rem 0">
                        <Quote
                            data={{
                                person: 'Patrick',
                                location: 'Eindhoven',
                                quote: 'Highly recommended for any busy freelancer!',
                                excerpt:
                                    'I keep track of my hours worked every day through the Tellow app. At the end of the month I send an invoice to my customers. This saves me a lot of time because this is done in a minute. And I look professional by using one of the invoice templates. Great!',
                                CTA: 'Read more reviews',
                                url: '/reviews',
                            }}
                        />
                    </ComponentContainer>
                </RelativeContainer>
            </ColoredBackground>

            <ColoredBackground purple>
                <RelativeContainer>
                    <ComponentContainer margin={0} padding="2rem 0">
                        <Split
                            placementOfTextBlock="left"
                            data={{
                                source: saveTimeImage,
                                title: 'Save time',
                                subtitle: 'Everything you need in one place',
                                body: 'With Tellow you have all administrative tools in a single place. You use Tellow wherever you want via the app or on your laptop. Your transactions are automatically imported. Scan your receipts and invoices, or upload documents. These are automatically recognized, categorized and linked to your imported transactions. This way, your entire administration is automated and always up-to-date. At the end of the quarter, you do your VAT return with one click of a button. Quick and easy.',
                                url: '/functies/facturen',
                                CTA: 'Choose a plan',
                            }}
                        />
                    </ComponentContainer>
                </RelativeContainer>
            </ColoredBackground>

            <ColoredBackground purple>
                <RelativeContainer>
                    <ComponentContainer margin={0} padding="1rem 0">
                        <Split
                            placementOfTextBlock="right"
                            data={{
                                source: saveMoneyImage,
                                title: 'Complete assurance',
                                subtitle: 'Always 100% correct declaration',
                                body: 'Work on your administration in a structured way. Is there a purchase invoice or sales invoice missing or is a transaction incorrect? We take you by the hand via a clear task list that states exactly what is still needed to complete your administration. We keep you informed of all deadlines and provide insight via the reports. Do you want an accountant? No problem. With Tellow Complete you will be matched with your own personal accountant.',
                                url: '/plan/compleet',
                                CTA: 'Discover Tellow Compleet',
                            }}
                        />
                    </ComponentContainer>
                </RelativeContainer>
            </ColoredBackground>

            <ColoredBackground purple>
                <RelativeContainer>
                    <ComponentContainer margin={0} padding="2rem 0">
                        <Split
                            placementOfTextBlock="left"
                            data={{
                                source: riskImage,
                                title: 'Earn more',
                                subtitle: 'More time and a professional look',
                                body: 'Spend less time on your administration and keep more time to work for customers (or free time of course). With the invoicing tool you send professional looking invoices in a jiffy. Send a payment request along so your customer can pay easily (and quickly). Using the quotation functionality you can create and send quotations. Approve them and send them out as invoices.',
                                url: '/functies/facturen',
                                CTA: 'Discover which plan suits you',
                            }}
                        />
                    </ComponentContainer>
                </RelativeContainer>
            </ColoredBackground>

            <ColoredBackground white>
                <RelativeContainer>
                    <ComponentContainer padding="2rem 0">
                        <LastButNotLeast>
                            <h3>Start using Tellow today</h3>
                            <p>View our plans, or create an account immediately.</p>

                            <Buttons>
                                <Button primary border href="/prijs">
                                    View plans
                                </Button>
                                <RegisterButton>
                                    Register now
                                    <span className="arrow">{'->'}</span>
                                </RegisterButton>
                            </Buttons>
                        </LastButNotLeast>
                    </ComponentContainer>
                </RelativeContainer>
            </ColoredBackground>
        </Layout>
    )
}

export default Functies
